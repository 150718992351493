import React, { Component } from 'react';
import { io } from "socket.io-client";

class WeatherBox extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="col-md-4 mt-4">
                <div className="shadow p-3">
                    <div className="d-flex weather-header"><h5>{ this.props.title }</h5></div>
                    <div className="d-flex align-items-center justify-content-center weather-content text-center">{ this.props.children }</div>
                </div>
            </div>
        );
    }
}

class Weather extends Component {
    constructor(props) {
        super(props);

	this._serverAddr = "https://api.wetter-heimbuchenthal.de/";

        this.state = {
            lastUpdate: 0,
            gotData: false,
            weather: {},
            min: {},
            max: {}
        };
        this.weather = {};
    }

    async _requestData() {
        const rawResponse = await fetch(this._serverAddr + "/weather/request", {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ "request": "now" })
        });

        const content = await rawResponse.json();

        if ("outdoorTemp" in content)
            this.setState( { weather: content, gotData: true, lastUpdate: 0 });
    }

    componentDidMount() {
	this.socket = io(this._serverAddr);
	this.socket.on("connect", () => { console.log("Connected to WebSocket!"); });

        this._requestData();
        this.socket.on("weather-ready", async (states) => {
            await this._requestData();
        });

        this.timerID = setInterval(() => {
            this.setState({ lastUpdate: this.state.lastUpdate + 1 });
        }, 1000);
    }

    componentWillUnmount() {
	this.socket.off("connected");
        this.socket.off("weather-states");
	this.socket = null;
        clearInterval(this.timerID);
    }

    updateWeatherStates(states) {
        this.setState({ lastUpdate: 0, weather: states, gotData: true, });
    }

    _renderSpinner() {
        return (
            <div className="container py-3 h-75">
                <div className="row h-100">
                    <div className="col d-flex justify-content-center text-center align-self-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Bitte warten...</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    _renderData() {
        return (
            <div className="container min-vh-100 py-3">
                <div className="row mb-4">
                    <div className="col text-end">Zuletzt aktualisiert vor: { this.state.lastUpdate } Sekunden</div>
                </div>
                <div className="row">
                    { /* Temperature */ }
                    <WeatherBox title="Temperatur">
                        <div className="row w-100 text-center">
                            <div className="col-md-6">
                                <h3>{ this.state.weather["outdoorTemp"]["data"]["current"]["value"] } { this.state.weather["outdoorTemp"]["data"]["current"]["unit"] }</h3>
                                <small className="me-3 Weather-min"><i className="bi bi-thermometer-low Weather-min"></i>
                                    { this.state.weather["outdoorTemp"]["data"]["min"]["value"] } { this.state.weather["outdoorTemp"]["data"]["min"]["unit"] }
                                </small>
                                <small className="Weather-max"><i className="bi bi-thermometer-high Weather-max"></i>
                                    { this.state.weather["outdoorTemp"]["data"]["max"]["value"] } { this.state.weather["outdoorTemp"]["data"]["max"]["unit"] }
                                </small>
                            </div>
                            <div className="col-md-6 align-self-center mt-3">
                                <div className="row">
                                    <div className="col-6 text-end">Gefühlt:</div>
                                    <div className="col-6 text-start">
                                        { this.state.weather["outdoorTemp"]["data"]["windChill"]["value"] } { this.state.weather["outdoorTemp"]["data"]["windChill"]["unit"] }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 text-end">Taupunkt:</div>
                                    <div className="col-6 text-start">
                                        { this.state.weather["outdoorTemp"]["data"]["dew"]["value"] } { this.state.weather["outdoorTemp"]["data"]["dew"]["unit"] }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </WeatherBox>

                    <WeatherBox title="Luftfeuchtigkeit">
                        <div className="w-100">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3>{ this.state.weather["outdoorHumidity"]["data"]["current"]["value"] } { this.state.weather["outdoorHumidity"]["data"]["current"]["unit"] }</h3>
                                    <small className="me-3 Weather-min"><i className="bi bi-arrow-bar-down Weather-min"></i>
                                        { this.state.weather["outdoorHumidity"]["data"]["min"]["value"] } { this.state.weather["outdoorHumidity"]["data"]["min"]["unit"] } %
                                    </small>
                                    <small className="Weather-max"><i className="bi bi-arrow-bar-up Weather-max"></i>
                                        { this.state.weather["outdoorHumidity"]["data"]["max"]["value"] } { this.state.weather["outdoorHumidity"]["data"]["max"]["unit"] }
                                    </small>
                                </div>
                            </div>
                        </div>
                    </WeatherBox>

                    <WeatherBox title="Luftdruck (Rel.)">
                        <div className="w-100">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3>{ this.state.weather["pressure"]["data"]["current"]["value"] } { this.state.weather["pressure"]["data"]["current"]["unit"] }</h3>
                                    <small className="me-3 Weather-min"><i className="bi bi-arrow-bar-down Weather-min"></i>
                                        { this.state.weather["pressure"]["data"]["min"]["value"] } { this.state.weather["pressure"]["data"]["min"]["unit"] }
                                    </small>
                                    <small className="Weather-max"><i className="bi bi-arrow-bar-up Weather-max"></i>
                                        { this.state.weather["pressure"]["data"]["max"]["value"] } { this.state.weather["pressure"]["data"]["max"]["unit"] }
                                    </small>
                                </div>
                            </div>
                        </div>
                    </WeatherBox>

                    <WeatherBox title="Wind">
                        <div className="w-100">
                            <div className="row w-100">
                                <div className="col"><h3>{ this.state.weather["wind"]["data"]["speed"]["value"] } { this.state.weather["wind"]["data"]["speed"]["unit"] }</h3></div>
                            </div>
                            <div className="row w-100 mt-3">
                                <div className="col-md-6">
                                    Böe
                                    <h3>{ this.state.weather["wind"]["data"]["gust"]["value"] } { this.state.weather["wind"]["data"]["gust"]["unit"] }</h3>
                                </div>
                                <div className="col-md-6">
                                    Richtung
                                    <h3>{ this.state.weather["wind"]["data"]["direction"]["value"] } { this.state.weather["wind"]["data"]["direction"]["unit"] }</h3>
                                </div>
                            </div>
                        </div>
                    </WeatherBox>

                    <WeatherBox title="Regen">
                        <div>
                            <div className="row mb-3">
                                <div className="col">
                                    Jetzt
                                    <h3>{ this.state.weather["rain"]["data"]["current"]["value"] } { this.state.weather["rain"]["data"]["current"]["unit"] }</h3>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <div className="row">
                                        <div className="col">
                                            Tag
                                            <h3>{ this.state.weather["rain"]["data"]["day"]["value"] } { this.state.weather["rain"]["data"]["day"]["unit"] }</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <dl className="row">
                                        <dt className="col-6 text-end fst-normal fw-light">Stunde</dt>
                                        <dd className="col-6 text-start fst-normal fw-light me-0">{ this.state.weather["rain"]["data"]["hour"]["value"] } { this.state.weather["rain"]["data"]["hour"]["unit"] }</dd>
                                        <dt className="col-6 text-end fst-normal fw-light">Woche</dt>
                                        <dd className="col-6 text-start fst-normal fw-light me-0">{ this.state.weather["rain"]["data"]["week"]["value"] } { this.state.weather["rain"]["data"]["week"]["unit"] }</dd>
                                        <dt className="col-6 text-end fst-normal fw-light">Monat</dt>
                                        <dd className="col-6 text-start fst-normal fw-light">{ this.state.weather["rain"]["data"]["month"]["value"] } { this.state.weather["rain"]["data"]["month"]["unit"] }</dd>
                                        <dt className="col-6 text-end fst-normal fw-light">Jahr</dt>
                                        <dd className="col-6 text-start fst-normal fw-light">{ this.state.weather["rain"]["data"]["year"]["value"] } { this.state.weather["rain"]["data"]["year"]["unit"] }</dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </WeatherBox>

                    <WeatherBox title="Sonne">
                        <div>
                            <div className="row">
                                <div className="col"><h3>{ this.state.weather["sun"]["data"]["current"]["value"] } { this.state.weather["sun"]["data"]["current"]["unit"] }</h3></div>
                            </div>
                            <div className="row">
                                <div className="col">UV Belastung: { this.state.weather["sun"]["data"]["uvIndex"]["value"] } / 10</div>
                            </div>
                        </div>
                    </WeatherBox>
                </div>
                <br />
                Version 2023.08.21
            </div>
        );
    }



    render() {
        if (this.state.gotData == true)
            return this._renderData();
        else
            return this._renderSpinner();
    }
}

export default Weather;
