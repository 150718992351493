import React, { useEffect } from 'react';
import './App.css';
import Weather from "./Weather/Weather"

function App() {
  return (
    <div className="App">
      <nav className="navbar bg-dark">
        <div className="container">
          <span className="navbar-brand mb-0 h1 text-white">Wetter in Heimbuchenthal</span>
        </div>
      </nav>
      <Weather />
    </div>
  );
}

export default App;
